/* You can add global styles to this file, and also import other style files */
/*@import '~@vi-iot/ui/styles/styles'; */
@import './colors';
@import './bootstrap';
@import './typography';
@import './theme.scss';
@import '@vi-succ/design-system-web/styles/dsw.css';

:root *:focus {
    outline: none;
}

label {
    margin-bottom: 0;
}

.mat-mdc-dialog-container {
    padding: 0 !important;
}

.mat-mdc-form-field-error-wrapper {
    padding: 0 !important;
    letter-spacing: 0;
    line-height: 14px;
}

.mat-drawer-container {
    background-color: $color-grey-light;
}

.mat-drawer-content {
    background-color: $color-grey-light;
}

.mat-mdc-button-base {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: normal;
    white-space: nowrap;
}

.mat-mdc-raised-button {
    height: 42px !important;
    border-radius: 28px !important;
    padding: 3px 50px !important;
}

.mat-mdc-outlined-button {
    height: 42px !important;
    border: 1px solid $color-black !important;
    border-radius: 28px !important;
}

.mat-mdc-snack-bar-container.error {
    --mdc-snackbar-container-color: #e05132;
}

.mat-mdc-snack-bar-container.success {
    --mdc-snackbar-container-color: #75c41b;
}

.mat-mdc-form-field-error,
.mat-mdc-form-field-hint {
    margin-top: 2px;
    display: inline-flex !important;
    font-size: 12px;
    font-family: 'Inter';
}
