@import 'node_modules/bootstrap/scss/bootstrap-grid.scss';
@import './variables.scss';
@import '../assets/fonts/Inter/inter';
@import '../assets/fonts/univers/Univers';
@import '../assets/fonts/material-icons/material-icons';
@import '../assets/fonts/roboto/roboto';

$inter: 'Inter';
$univers: 'Univers';

body {
    font-family: $inter;
}

h1 {
    font-family: $inter;
    font-size: 24px;
    font-weight: 500;
}

h2 {
    font-family: $inter;
    font-size: 20px;
    font-weight: bold;
}

h3 {
    font-family: $inter;
    font-size: 15px;
}

a {
    color: $color-black !important;
    &:not(.register-link) {
        .mdc-button__label {
            color: $color-white !important;
        }
    }
    text-decoration: none;
}

p {
    font-family: $inter;
    font-size: 15px;
}

.mdc-form-field > label {
    font-size: 16px;
}

.text-small {
    font-size: 13px;
}

.text-sixe-xl {
    font-size: 40px;
}

.text-size-xs {
    font-size: 12px;
}

.mat-mdc-tooltip {
    font-size: 10px;
}

.mat-mdc-dialog-container .mdc-dialog__content {
    color: inherit !important;
}

.mat-mdc-dialog-container .mdc-dialog__content {
    line-height: inherit !important;
    font-size: inherit !important;
    font-weight: inherit !important;
    letter-spacing: inherit !important;
}

.mat-mdc-table tbody,
.mat-mdc-table tfoot,
.mat-mdc-table thead,
.mat-mdc-cell,
.mat-mdc-footer-cell,
.mat-mdc-header-row,
.mat-mdc-row,
.mat-mdc-footer-row,
.mat-mdc-table .mat-mdc-header-cell {
    font-size: 14px;
    font-family: 'Inter';
}

.dark-mode {
    html,
    body {
        font-family: $inter;
        font-size: 16px;
    }

    h1,
    h2,
    h3,
    a,
    p {
        color: $color-white !important;
    }

    h1,
    h2,
    h3 {
        font-family: $univers !important;
    }

    h1 {
        font-weight: 700;
        margin-bottom: 24px;
        letter-spacing: -0.01em;
        text-align: left;
        @include media-breakpoint-up(xl) {
            font-size: 56px !important;
            line-height: 60px !important;
        }

        @include media-breakpoint-down(md) {
            font-size: 40px !important;
            line-height: 44px !important;
        }

        @include media-breakpoint-down(sm) {
            font-size: 24px !important;
            line-height: 28px !important;
        }
    }

    h2 {
        font-family: $univers;
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 24px;
    }

    p {
        font-family: $inter;
        font-size: 16px;
        line-height: 24px;
        font-weight: 300;
        text-align: left;
        color: $color-white;

        &.error-paragraph {
            font-size: 24px;
            line-height: 24px;
            margin: 40px 0;
        }

        &.intro-paragraph {
            font-size: 26px;
            line-height: 36px;
            margin-bottom: 40px;

            @include media-breakpoint-down(sm) {
                font-size: 18px;
                line-height: 28px;
            }
        }

        &.required-hint,
        &.legal-hint {
            font-size: 12px;
            color: $color-hint-grey !important;
            margin-bottom: 40px;
        }
    }

    .mdc-form-field > label {
        font-family: 'Inter';
    }

    b {
        font-family: 'Inter';
        font-weight: 700;

        color: $color-white;
    }

    .help-text {
        b {
            font-size: 26px;
            line-height: 36px;
        }
    }

    input.mat-mdc-input-element {
        font-family: $inter;
        font-size: 16px;
        line-height: 24px;
        font-weight: 300;
    }

    .mat-mdc-form-field-hint {
        color: $color-hint-grey;
        font-size: 12px;
        margin-top: 8px;
        font-family: $inter;
        line-height: 24px;
        font-weight: 300;
    }

    .mat-mdc-option {
        font-size: 16px;
        font-family: $inter;
        font-weight: 300;
        color: $color-black;
    }

    .mat-mdc-input-element::placeholder {
        color: $color-grey !important;
        font-family: $inter !important;
    }

    .mat-mdc-form-field-error {
        color: $color-red;
        font-size: 12px;
        line-height: 24px;
        font-weight: 400;
        font-family: $inter;
    }
}
