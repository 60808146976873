@font-face {
    font-family: 'ViessmannPro';
    src: url('viessmann-pro-regular.eot');
    src: url('viessmann-pro-regular.eot?#iefix') format('embedded-opentype'),
    url('viessmann-pro-regular.woff2') format('woff2'),
    url('viessmann-pro-regular.woff') format('woff'),
    url('viessmann-pro-regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
