@use '@angular/material' as mat;
@import '../styles/bootstrap_essentials';
@import './colors';

@include mat.core();
$primary-palette: (
    50: #f6f6f6,
    100: #f0f0f0,
    200: #dedede,
    300: #d0d0d0,
    400: #858585,
    500: #000000,
    600: #404040,
    700: #353535,
    800: #242424,
    900: #000000,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

$accent-palette: (
    50: #e7e8ea,
    100: #c3c5cb,
    200: #9c9fa9,
    300: #747986,
    400: #565c6c,
    500: #383f52,
    600: #32394b,
    700: #2b3141,
    800: #242938,
    900: #171b28,
    A100: #6d8cff,
    A200: #3a64ff,
    A400: #073cff,
    A700: #0033ec,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #ffffff,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #ffffff,
        A400: #ffffff,
        A700: #ffffff,
    ),
);

$warn-palette: (
    50: #ffe8e3,
    100: #ffc5b9,
    200: #ff9f8b,
    300: #ff795d,
    400: #ff5c3a,
    500: #ff3f17,
    // main red color
    600: #ff3914,
    700: #ff3111,
    800: #ff290d,
    900: #ff1b07,
    A100: #ffffff,
    A200: #fff5f4,
    A400: #ffc4c1,
    A700: #ffaca7,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

$fe-ccr-primary: mat.define-palette($primary-palette);
$fe-ccr-accent: mat.define-palette($accent-palette);

// The warn palette is optional (defaults to red).
$fe-ccr-warn: mat.define-palette($warn-palette);

// theming systems such as "color" or "typography".
$fe-ccr-theme: mat.define-light-theme(
    (
        color: (
            primary: $fe-ccr-primary,
            accent: $fe-ccr-accent,
            warn: $fe-ccr-warn,
        ),
    )
);

@include mat.core-theme($fe-ccr-theme);
@include mat.all-component-themes($fe-ccr-theme);

body.dark-mode {
    $primary-dark: (
        Main: $color-outline-grey,
        Light: $color-outline-grey,
        Dark: $color-outline-grey,
        contrast: (
            Main: red,
            Light: green,
            Dark: blue,
        ),
    );

    $accent-dark: (
        Main: white,
        Light: #545454,
        Dark: #545454,
        contrast: (
            Main: #000000,
            Light: #000,
            Dark: #fff,
        ),
    );

    $warn-dark: (
        Main: #ff3e17,
        Light: #ff3e17,
        Dark: #ff3e17,
        contrast: (
            Main: white,
            Light: #000,
            Dark: #fff,
        ),
    );

    $fe-ccr-primary-dark: mat.define-palette($primary-dark, Main, Light, Dark);

    $fe-ccr-accent-dark: mat.define-palette($accent-dark, Main, Light, Dark);

    $fe-ccr-warn-dark: mat.define-palette($warn-dark, Main, Light, Dark);

    $fe-ccr-theme-dark: mat.define-dark-theme(
        (
            color: (
                primary: $fe-ccr-primary-dark,
                accent: $fe-ccr-accent-dark,
                warn: $fe-ccr-warn-dark,
            ),
        )
    );

    //@include mat.all-component-themes($fe-ccr-theme-dark);

    // This mixin only generates the color styles now.
    @include mat.all-component-colors($fe-ccr-theme-dark);
    @include mat.progress-spinner-color($fe-ccr-theme);

    background: $color-black;
    font-size: 0.5em;

    .container {
        margin: 120px auto;
    }

    @include media-breakpoint-down(md) {
        div:has(> iframe) {
            top: -10vh !important;
        }
    }

    @include media-breakpoint-up(xl) {
        .container {
            max-width: 1224px;
        }
    }

    .mat-mdc-form-field {
        margin-bottom: 16px;
    }

    .mdc-text-field--outlined .mdc-notched-outline {
        z-index: 0;
    }

    .mdc-text-field .mdc-text-field__input {
        &:not(.mdc-text-field--invalid) {
            color: black;
        }
    }

    .mdc-text-field.mdc-text-field--disabled .mdc-text-field__input {
        &:not(.mdc-text-field--invalid) {
            color: $color-grey;
        }
    }

    .mdc-text-field--outlined .mdc-notched-outline__leading,
    .mdc-text-field--outlined .mdc-notched-outline__notch,
    .mdc-text-field--outlined .mdc-notched-outline__trailing {
        background-color: white;
    }

    .mdc-notched-outline__leading {
        border-left: 2px solid;
        border-right: none;
        border-top: 2px solid;
        border-bottom: 2px solid;
    }

    .mdc-notched-outline__trailing {
        border-left: none;
        border-right: 2px solid;
        border-top: 2px solid;
        border-bottom: 2px solid;
    }

    .mat-mdc-icon-button.mat-mdc-button-base {
        width: 40px;
        height: 40px;
        padding: 0;
    }

    .mat-mdc-form-field-infix {
        display: flex;
        height: 40px;
        min-height: 40px !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .mat-mdc-radio-button.mat-primary {
        --mdc-radio-disabled-selected-icon-color: #fff;
        --mdc-radio-disabled-unselected-icon-color: #fff;
        --mdc-radio-unselected-focus-icon-color: #fff;
        --mdc-radio-unselected-hover-icon-color: #fff;
        --mdc-radio-unselected-icon-color: #fff;
        --mdc-radio-unselected-pressed-icon-color: #fff;
        --mdc-radio-selected-focus-icon-color: #ff3e17;
        --mdc-radio-selected-hover-icon-color: #ff3e17;
        --mdc-radio-selected-icon-color: #ff3e17;
        --mdc-radio-selected-pressed-icon-color: #ff3e17;
        --mat-mdc-radio-ripple-color: #ff3e17;
        --mat-mdc-radio-checked-ripple-color: #ff3e17;
    }

    .mat-mdc-checkbox.mat-primary {
        --mdc-checkbox-selected-checkmark-color: #fff;
        --mdc-checkbox-selected-focus-icon-color: #ff3e17;
        --mdc-checkbox-selected-hover-icon-color: #ff3e17;
        --mdc-checkbox-selected-icon-color: #ff3e17;
        --mdc-checkbox-selected-pressed-icon-color: #ff3e17;
        --mdc-checkbox-unselected-focus-icon-color: #fff;
        --mdc-checkbox-unselected-hover-icon-color: #fff;
        --mdc-checkbox-unselected-icon-color: #fff;
        --mdc-checkbox-unselected-pressed-icon-color: #ff3e17;
    }

    .mat-mdc-select-value {
        color: $color-black;
        font-family: 'Inter';
        font-size: 16px;
        line-height: 24px;
        font-weight: 300;
    }

    .mat-mdc-select-disabled .mat-mdc-select-value {
        color: $color-grey;
    }

    .mat-mdc-select-trigger {
        height: 40px;
    }

    .mat-mdc-select-arrow svg {
        fill: $color-black;
    }

    .mdc-menu-surface {
        background-color: $color-white;
    }

    .mdc-list-item__primary-text,
    .mat-primary .mat-mdc-option.mdc-list-item--selected .mdc-list-item__primary-text,
    .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after {
        color: $color-black;
    }

    .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple) {
        background-color: rgba(0, 0, 0, 0.12);
    }

    .mat-mdc-option:hover:not(.mdc-list-item--disabled) {
        background-color: rgba(0, 0, 0, 0.04);
    }

    .mat-mdc-select-placeholder {
        color: rgba(0, 0, 0, 0.6);
    }

    a {
        color: $color-viessmann-red !important;
        span {
            font-size: 16px;
        }
    }

    .mat-mdc-button-base {
        font-size: 16px;
    }

    .mat-mdc-raised-button {
        min-height: 56px;
        min-width: 250px;
        border-radius: 200px;
        background-color: $color-viessmann-red;
        .mdc-button__label {
            color: $color-white;
            font-family: 'Inter';
            font-weight: 700;
            font-size: 16px;
            line-height: 18px;
        }
        &.mat-mdc-raised-button[disabled][disabled] {
            background-color: $color-btn-disabled;
        }
    }

    .mat-mdc-raised-button.mat-accent {
        background-color: $color-black;
        border: 1px solid $color-white;
    }

    app-alert {
        div.alert {
            background: rgba(224, 81, 50, 0.2) !important;
            border: 2px solid $color-red;
            border-radius: 4px;
            color: $color-white;
            font-size: 16px;
            line-height: 26px;
            margin-bottom: 40px;
        }
    }
}
