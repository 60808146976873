$color-white: #fff;
$color-grey-light: #f5f5f5;
$color-grey: #545454;
$color-black: #000;
$color-red: #e05132;
$color-viessmann-red: #ff3e17;

$color-green: #75c41b;
$color-toggle-green: #2cc84d;
$color-yellow: #f9ab02;
$color-btn-disabled: #858585;
$color-hint-grey: #d0d0d0;
$color-outline-grey: #dedede;

$colors: (
    'white': $color-white,
    'grey-light': $color-grey-light,
    'grey': $color-grey,
    'black': $color-black,
    'red': $color-red,
    'green': $color-green,
    'yellow': $color-yellow,
    'viessmann-red': $color-viessmann-red,
);
